import update from 'immutability-helper';
import actionTypes from '../actions/action.types';

const initialState = {
  order: {}
};

const setOrder = (state, action) => {
  return update(state, {
    order: {
      $set: action.order
    }
  });
};

export const reducer = (state = initialState, action) => {
  if (action.type === actionTypes.ORDER_SET) {
    return setOrder(state, action);
  } else {
    return {
      ...state
    };
  }
};

export default reducer;
