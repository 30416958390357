import React, { Component } from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';
import { notification } from 'antd';
import Navbar from '../components/Layout/Header/Navbar';
import Footer from '../components/Layout/Footer/Footer';
import { authCheck } from '../store/actions/auth.actions';
import setStoreSettings from '../store/actions/settings.actions';
import setStores from '../store/actions/store.actions';
import { syncFavorites } from '../store/actions/customer.actions';
import { withTranslation } from '../i18n/i18n';

notification.config({
  duration: 5
});

class Layout extends Component {
  async componentDidMount() {
    this.prepare();
  }

  componentDidUpdate() {
    const { search } = this.props;
    if (search) {
      if (search.searchContainer) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  }

  prepare = async () => {
    const {
      auth,
      checkAuth,
      setPhysicalStores,
      setSettings,
      settings,
      stores,
      syncFavs
    } = this.props;

    await checkAuth();
    setSettings(settings);
    setPhysicalStores(stores);
    if (auth.authenticated) {
      syncFavs();
    }
  };

  render() {
    const { categories, children, search } = this.props;

    return (
      <div
        className={[
          'layout-wrapper',
          search ? (search.searchContainer ? 'search-is-active' : '') : ''
        ].join(' ')}
      >
        <div className="layout-container">
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href="/static/favicons/apple-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href="/static/favicons/apple-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href="/static/favicons/apple-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href="/static/favicons/apple-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href="/static/favicons/apple-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href="/static/favicons/apple-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href="/static/favicons/apple-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/static/favicons/apple-icon-152x152.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/static/favicons/apple-icon-180x180.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/static/favicons/android-icon-192x192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/static/favicons/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href="/static/favicons/favicon-96x96.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/static/favicons/favicon-16x16.png"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="/static/favicons/ms-icon-144x144.png"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i,900,900i&display=swap&subset=greek-ext"
              rel="stylesheet"
            />
            <meta name="theme-color" content="#ffffff" />
            {/* <style
              id="flashStyle"
              dangerouslySetInnerHTML={{
                __html: `
                          *, *::before, *::after {
                          transition: none!important;
                }`
              }}
            /> */}
          </Head>
          <Navbar categories={categories} />
          <main className="main-container">{children}</main>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.search
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(authCheck()),
  setPhysicalStores: (stores) => dispatch(setStores(stores)),
  setSettings: (settings) => dispatch(setStoreSettings(settings)),
  syncFavs: () => dispatch(syncFavorites())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('navbar')(Layout));
