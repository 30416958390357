import update from 'immutability-helper/index';
import { checkOnlyPrice, checkPrice, transformProduct } from '../../utils/utils';

const initialState = {
  products: []
};

const addToCart = (state, action) => {
  const { product, quantity } = action;
  return update(state, {
    products: {
      $apply: (products) => {
        const index = products.findIndex((item) => {
          return item._id === product._id;
        });
        if (index !== -1) {
          return update(products, {
            [index]: {
              quantity: {
                $set: /* products[index].quantity + */ quantity
              },
              totalPrice: {
                $set: checkPrice(
                  products[index],
                  /* products[index].quantity + */ quantity
                )
              }
            }
          });
        } else if (index === -1) {
          product.quantity = action.quantity;
          product.totalPrice = checkPrice(product, quantity);
          return update(products, {
            $push: [product]
          });
        }
        return products;
      }
    }
  });
};

const addMutlipleProductsToCart = (state, action) => {
  const { products } = action;
  let updatedProducts = [];
  return update(state, {
    products: {
      $apply: (items) => {
        // eslint-disable-next-line array-callback-return
        products.map((item) => {
          const index = items.findIndex((prd) => prd._id === item._id);
          if (index > -1) {
            updatedProducts = update(items, {
              [index]: {
                quantity: {
                  $set: items[index].quantity + item.quantity
                },
                totalPrice: {
                  $set: checkPrice(items[index], item.quantity)
                }
              }
            });
          } else if (index === -1) {
            updatedProducts = update(updatedProducts, {
              $push: [
                transformProduct({ ...item, totalPrice: checkPrice(item, item.quantity) })
              ]
            });
          }
        });
        return updatedProducts;
      }
    }
  });
};

const onQuantityChange = (state, action) => {
  const { product, value } = action;
  // console.log(action, "ACTION ONQUANTITYCHANGE");
  let temp = value;
  // console.log(temp, '----TEMP');
  if (!temp) {
    temp = 1;
  } else {
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] === '0') {
        temp = temp.substr(1, temp.length);
      } else {
        break;
      }
    }
  }

  if (!temp) {
    temp = 1;
  }

  return update(state, {
    products: {
      $apply: (products) => {
        const index = products.findIndex((item) => item._id === product._id);
        if (index !== -1) {
          return update(products, {
            [index]: {
              quantity: {
                $set: temp // value
              },
              totalPrice: {
                $set: temp * checkOnlyPrice(products[index]) // value * checkOnlyPrice(products[index])
              }
            }
          });
        }
        return products;
      }
    }
  });
};

const deleteProduct = (state, action) => {
  const { index } = action;
  return update(state, {
    products: { $splice: [[index, 1]] }
  });
};

const resetCart = (state) => {
  return update(state, {
    products: { $set: [] }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CART_ADD_PRODUCT':
      return addToCart(state, action);
    case 'CART_REMOVE_PRODUCT':
      return deleteProduct(state, action);
    case 'CART_CHANGE_PRODUCT_QUANTITY':
      return onQuantityChange(state, action);
    case 'CART_ADD_MULTIPLE_PRODUCTS':
      return addMutlipleProductsToCart(state, action);
    case 'CART_RESET':
      return resetCart(state);
    default:
      return {
        ...state
      };
  }
};

export default reducer;
