import React from 'react';
import Link from 'next/link';
import { Col, Divider, Row } from 'antd';

const FooterDesktop = ({ stores }) => {
  return (
    <>
      <Row className="mb-70">
        <Divider type="horizontal" />
      </Row>
      <Row className="pl-5" gutter={70} type="flex" justify="center">
        {/* ΧΡΗΣΙΜΟΙ ΣΥΝΔΕΣΜΟΙ */}
        <Col span={6}>
          <Row type="flex">
            <Col className="mb-10" span={24}>
              <a className="text-16-bold">ΧΡΗΣΙΜΟΙ ΣΥΝΔΕΣΜΟΙ</a>
            </Col>
            <Col span={24}>
              <Link href="/about">
                <a className="text-16-normal">Η εταιρεία</a>
              </Link>
            </Col>
            <Col span={24}>
              <Link href="/terms">
                <a className="text-16-normal">Όροι Χρήσης</a>
              </Link>
            </Col>
            <Col span={24}>
              <Link href="/returns">
                <a className="text-16-normal">Πολιτική Επιστροφών</a>
              </Link>
            </Col>
            <Col span={24}>
              <Link href="/payment">
                <a className="text-16-normal">Πολιτική Πληρωμών</a>
              </Link>
            </Col>
            <Col span={24}>
              <Link href="/shipping">
                <a className="text-16-normal">Μεταφορικά</a>
              </Link>
            </Col>
          </Row>
        </Col>
        {/* ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ */}
        <Col span={6}>
          <Row type="flex">
            <Col className="mb-10" span={24}>
              <span className="text-16-bold">ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ</span>
            </Col>
            <Col span={24}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Easis-%CE%9A%CE%B5%CE%BD%CF%84%CF%81%CE%BF-%CE%95%CE%BD%CE%B1%CE%BB%CE%BB%CE%B1%CE%BA%CF%84%CE%B9%CE%BA%CE%B7%CF%82-%CE%99%CE%B1%CF%84%CF%81%CE%B9%CE%BA%CE%B7%CF%82-102375501394519/?__tn__=%2Cd%2CP-R&eid=ARAoxbGECTVJZK8EiL9wSiFpr7poUl6js4suL4Hb3pY2VSo9f-RGC9Mfl0TV_pMxrR0WA0jDYKQS1RGD"
              >
                <span className="text-16-normal">Facebook</span>
              </a>
            </Col>
            <Col span={24}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/easis.greece/"
              >
                <span className="text-16-normal">Instagram</span>
              </a>
            </Col>
            <Col span={24}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Easis_greece"
              >
                <span className="text-16-normal">Twitter</span>
              </a>
            </Col>
          </Row>
        </Col>
        {/* ΚΑΤΑΣΤΗΜΑΤΑ */}
        <Col span={6}>
          <Row type="flex">
            <Col className="mb-10" span={24}>
              <span className="text-16-bold">ΚΑΤΑΣΤΗΜΑΤΑ</span>
            </Col>
            {stores?.map((store) => {
              return (
                <Col className="mb-20" span={24} key={store._id}>
                  <Row type="flex">
                    <Col span={24}>
                      <span className="text-16-bold">{store.name}</span>
                    </Col>
                    <Col span={24}>
                      <span className="text-16-normal">{store.address_1}</span>
                    </Col>
                    <Col span={24}>
                      <span className="text-16-normal">
                        Τηλέφωνο <a href={`tel:${store.phone_1}`}> {store.phone_1}</a>
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className="text-16-normal">FAX {store.fax}</span>
                    </Col>
                    <Col span={24}>
                      <span className="text-16-normal">
                        Email <a href={`mailto:${store.email_1}`}>{store.email_1}</a>
                      </span>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
        {/* ΩΡΑΡΙΟ ΚΑΤΑΣΤΗΜΑΤΩΝ */}
        <Col span={6}>
          <Row type="flex">
            <Col className="mb-10" span={24}>
              <span className="text-16-bold">ΩΡΑΡΙΟ ΚΑΤΑΣΤΗΜΑΤΩΝ</span>
            </Col>
            <Col className="mb-50" span={24}>
              <Row type="flex">
                <Col span={24}>
                  <span className="text-16-normal">ΔΕΥΤΕΡΑ - ΠΑΡΑΣΚΕΥΗ</span>
                </Col>
                <Col span={24}>
                  <span className="text-16-normal">12.00 - 21.00</span>
                </Col>
              </Row>
            </Col>
            <Col className="mt-10" span={24}>
              <img
                style={{ width: '100%' }}
                alt="everypay"
                src="static/images/everypay.svg"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <Divider type="horizontal" />
        </Col>
        <Col span={24}>
          <Row
            className="footer-container mt-30 mb-30"
            type="flex"
            justify="center"
            align="middle"
          >
            <Col span={12} align="center">
              <span className="text-12-bold">TWENTY ONE</span>
              <span className="text-12-normal"> PRODUCTION</span>
            </Col>
            <Col span={12} align="center">
              <span className="text-12-normal"> ECOMMERCE PLATFORM NEXTCOMMERCE</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FooterDesktop;
