import update from 'immutability-helper';
import { FETCH_PRODUCT_INIT, FETCH_PRODUCT_SUCCESS } from '../actions/types';

const initialState = {
  isLoading: false,
  products: []
};

const fetchProductInit = () => {
  return update(initialState, {
    isLoading: {
      $set: true
    }
  });
};

const fetchProductSuccess = (state, action) => {
  return update(initialState, {
    isLoading: {
      $set: false
    },
    products: {
      $set: action.payload.data
    }
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_INIT:
      return fetchProductInit(state);
    case FETCH_PRODUCT_SUCCESS:
      return fetchProductSuccess(state, action);
    default:
      return state;
  }
};
