import update from 'immutability-helper';

const initialState = {
  brands: [],
  categoryAncestors: [],
  categoryUrl: '',
  count: 0,
  loading: false,
  order: 'price',
  orderType: 'priceDescending',
  page: 1,
  pageSize: '12',
  pageSizes: ['12', '24', '36', '48', '60', '72', '84', '96'],
  parentCategoryUrl: '',
  products: [],
  range: { min: 0, max: 0 },
  redrawKey: 1,
  sort: -1,
  tags: [],
  search: '',
  searchContainer: false
};

const setCatalogPage = (state, action) => {
  return update(state, {
    page: {
      $set: action.page
    }
  });
};

const resetCatalogPage = (state) => {
  return update(state, {
    page: {
      $set: 1
    }
  });
};

const fetchProductsInit = (state) => {
  return update(state, {
    loading: {
      $set: true
    }
  });
};

const fetchProductsFail = (state) => {
  return update(state, {
    loading: {
      $set: false
    }
  });
};

const updateRedrawKey = (state) => {
  return update(state, {
    redrawKey: {
      $set: state.redrawKey + 1
    }
  });
};

const changeCatalogPageSize = (state, action) => {
  return update(state, {
    pageSize: { $set: action.pageSize },
    page: { $set: action.page }
  });
};

const resetCatalog = (state, action) => {
  return update(state, {
    brands: { $set: [] },
    categoryAncestors: { $set: [] },
    categoryUrl: { $set: '' },
    count: { $set: 0 },
    page: { $set: 1 },
    products: { $set: [] },
    tags: { $set: [] },
    parentCategoryUrl: { $set: action.parent },
    redrawKey: { $set: state.redrawKey + 1 }
  });
};

const setProducts = (state, action) => {
  return update(state, {
    brands: { $set: action.brands },
    categoryAncestors: { $set: action.categoryAncestors },
    categoryUrl: { $set: action.categoryUrl },
    count: { $set: action.count },
    order: { $set: action.order },
    page: { $set: action.page },
    pageSize: { $set: action.pageSize },
    products: { $set: action.products },
    sort: { $set: action.sort },
    tags: { $set: action.tags }
  });
};

const setSortOrder = (state, action) => {
  return update(state, {
    order: { $set: action.order },
    sort: { $set: action.sort },
    orderType: { $set: action.orderType }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CATALOG_CHANGE_PAGE_SIZE':
      return changeCatalogPageSize(state, action);
    case 'CATALOG_CHANGE_PAGE':
      return setCatalogPage(state, action);
    case 'CATALOG_RESET_PAGE':
      return resetCatalogPage(state);
    case 'CATALOG_SET_PRODUCTS':
      return setProducts(state, action);
    case 'CATALOG_CHANGE_SORT_ORDER':
      return setSortOrder(state, action);
    case 'CATALOG_FETCH_PRODUCTS_INIT':
      return fetchProductsInit(state);
    case 'CATALOG_FETCH_PRODUCTS_FAIL':
      return fetchProductsFail(state);
    case 'CATALOG_RESET':
      return resetCatalog(state, action);
    case 'CATALOG_UPDATE_REDRAW_KEY':
      return updateRedrawKey(state);
    default:
      return {
        ...state
      };
  }
};

export default reducer;
