import { Col, Drawer, Row } from 'antd';
import Link from 'next/link';
import Router, { withRouter } from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { apiUrlPublic, axiosCallApi } from '../../../../config/config';
import { withTranslation } from '../../../i18n/i18n';
import { Media } from '../../../Media';
import { logout } from '../../../store/actions/auth.actions';
import { resetCart } from '../../../store/actions/cart.actions';
import { resetCatalog } from '../../../store/actions/catalog.actions';
import {
  clearFavorites,
  updateCart,
  updateFavorites
} from '../../../store/actions/customer.actions';
import { resetAllFilters } from '../../../store/actions/filters.actions';
import {
  resetSearchContainer,
  resetSearchText,
  setSearchContainer,
  setSearchText,
  startSearchClickLoading,
  startSearchLoading,
  stopSearchClickLoading,
  stopSearchLoading
} from '../../../store/actions/search.actions';
import { NavbarDesktop } from './components/Desktop/NavbarDesktop';
import NavbarMobile from './components/Mobile/NavbarMobile';
import MobileMenu from './components/MobileMenu';
import './Navbar.less';

class Navbar extends React.Component {
  state = {
    current: 'home',
    display: 'none',
    products: [],
    searchVisible: false,
    total: 0,
    drawerVisible: false
  };

  onMouseEnter = () => {
    this.setState({ display: 'block' });
  };

  onMouseLeave = () => {
    this.setState({ display: 'none' });
  };

  handleMenuClick = (e) => {
    this.setState({
      current: e.key
    });
  };

  clickedOutside = () => {
    const {
      resetSearchContainer: resetContainer,
      resetSearchText: resetText,
      resetFilters
    } = this.props;

    resetContainer();
    resetText();
    resetFilters();
    this.setState({ searchVisible: false });
  };

  // eslint-disable-next-line consistent-return
  onSearchProducts = async (e) => {
    const {
      resetSearchContainer: resetContainer,
      resetSearchText: resetText,
      setSearchContainer: setContainer,
      setSearchText: setText,
      startSearchLoading: startLoading,
      stopSearchLoading: stopLoading,
      resetFilters
    } = this.props;
    const { value } = e.target;

    if (!value) {
      resetContainer();
      resetText();
      resetFilters();
      return this.setState({
        products: [],
        searchVisible: false,
        total: []
      });
    }

    try {
      startLoading();
      setText(value);
      const fetchProducts = await axiosCallApi.post(
        `${apiUrlPublic}/product/search/?search=${value}&limit=${4}`
      );
      const { products, total } = await fetchProducts.data;
      this.setState({
        products,
        //  text: value,
        searchVisible: true, // products.length > 0,
        total: total.count
      });
      stopLoading();
      if (products.length > 0) {
        setContainer();
      } else {
        resetContainer();
      }
    } catch (error) {
      this.setState({ searchVisible: false });
      stopLoading();
      resetContainer();
    }
  };

  goToProduct = async (product) => {
    const { resetSearchContainer: resetContainer } = this.props;
    this.setState({ searchVisible: false });
    resetContainer();
    // Router.push({pathname: '`/product', {query: {slug: }} }
    //   `/product?slug=${encodeURIComponent(product.slug)}`,
    //   `/product/${product.slug}`
    // );
    await Router.push(
      {
        pathname: '/product',

        query: {
          slug: encodeURIComponent(product.slug)
        }
      },
      `/product/${product.slug}`,
      { shallow: false }
    );
  };

  onClickShowAll = async () => {
    const {
      resetFilters,
      resetSearchContainer: resetContainer,
      search,
      startSearchClickLoading: startClickLoading,
      stopSearchClickLoading: stopClickLoading
    } = this.props;
    try {
      startClickLoading();
      this.setState({ searchVisible: false });
      resetContainer();
      await resetFilters();

      await Router.push(
        `/catalog?location=&search=${search.text}`,
        `/catalog?location=&search=${search.text}`
      );

      stopClickLoading();
    } catch (error) {
      console.log(error);
    }
  };

  toggleDrawer = () => {
    this.setState({ drawerVisible: !this.state.drawerVisible });
  };

  goToCategory = () => {
    this.setState({ drawerVisible: false });
  };

  render() {
    const {
      auth,
      cart,
      categories,
      customer,
      filters,
      logoutUser,
      resetProductCatalog,
      resetProductFilters,
      resetSearchText: resetText,
      router,
      search,
      settings,
      t
    } = this.props;
    const {
      current,
      display,
      drawerVisible,
      products,
      searchValue,
      searchVisible,
      setSearchValue,
      total
    } = this.state;

    const signOut = () => {
      logoutUser();
      router.push('/');
    };

    // account dropdown
    const accountDropdown = (
      <Row gutter={10}>
        <Col className="hover pl-2 pr-2" align="right">
          <Link href="/account">
            <span className="text-16-normal p-3">{t('AccountDrawer.accountInfo')}</span>
          </Link>
        </Col>
        <Col className="hover mt-10" align="right">
          <span className="text-16-normal" onClick={signOut}>
            {t('AccountDrawer.logoutButton')}
          </span>
        </Col>
      </Row>
    );

    return (
      <div>
        {/* Navbar Mobile */}
        <Media lessThan="lg">
          <div className="navbar-mobile navbar-padding">
            <NavbarMobile
              accountDropdown={accountDropdown}
              auth={auth}
              cart={cart}
              categories={categories}
              clickedOutside={this.clickedOutside}
              current={current}
              customer={customer}
              display={display}
              handleMenuClick={this.handleMenuClick}
              onClickProduct={this.goToProduct}
              onClickShowAll={this.onClickShowAll}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onSearchProducts={this.onSearchProducts}
              products={products}
              search={search}
              searchPlaceholder="Αναζήτηση Προιόντων"
              searchValue={searchValue}
              searchVisible={searchVisible}
              setSearchValue={setSearchValue}
              settings={settings}
              t={t}
              toggleDrawer={this.toggleDrawer}
              total={total}
            />
          </div>
        </Media>
        {/* Navbar Desktop */}
        <Media greaterThanOrEqual="lg">
          <div className="navbar-padding">
            <NavbarDesktop
              accountDropdown={accountDropdown}
              auth={auth}
              cart={cart}
              categories={categories}
              clickedOutside={this.clickedOutside}
              current={current}
              customer={customer}
              display={display}
              handleMenuClick={this.handleMenuClick}
              onClickShowAll={this.onClickShowAll}
              onClickProduct={this.goToProduct}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onSearchProducts={this.onSearchProducts}
              products={products}
              search={search}
              searchPlaceholder="Αναζήτηση Προιόντων"
              searchValue={searchValue}
              searchVisible={searchVisible}
              setSearchValue={setSearchValue}
              settings={settings}
              t={t}
              total={total}
            />
          </div>
        </Media>
        <Drawer
          closable
          onClose={this.toggleDrawer}
          placement="left"
          visible={drawerVisible}
          width="100%"
        >
          <MobileMenu
            filters={filters}
            resetProductCatalog={resetProductCatalog}
            resetProductFilters={resetProductFilters}
            resetText={resetText}
            drawerHeader={t('mobileMenuHeader')}
            menu={categories}
            goToCategory={this.goToCategory}
            toggleDrawer={this.toggleDrawer}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cart: state.cart,
    customer: state.customer,
    favorites: state.customer.favorites,
    filters: state.filters,
    search: state.search,
    settings: state.settings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyFavorites: () => dispatch(clearFavorites()),
    logoutUser: () => dispatch(logout()),
    resetCustomerCart: () => dispatch(resetCart()),
    resetFilters: () => dispatch(resetAllFilters()),
    resetProductCatalog: (parent) => dispatch(resetCatalog(parent)),
    resetProductFilters: (parent) => dispatch(resetAllFilters(parent)),
    resetSearchContainer: () => dispatch(resetSearchContainer()),
    resetSearchText: () => dispatch(resetSearchText()),
    setCart: (cart) => dispatch(updateCart(cart)),
    setFavorites: (favorites) => dispatch(updateFavorites(favorites)),
    setSearchContainer: () => dispatch(setSearchContainer()),
    setSearchText: (text) => dispatch(setSearchText(text)),
    startSearchClickLoading: () => dispatch(startSearchClickLoading()),
    startSearchLoading: () => dispatch(startSearchLoading()),
    stopSearchClickLoading: () => dispatch(stopSearchClickLoading()),
    stopSearchLoading: () => dispatch(stopSearchLoading())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('navbar')(Navbar))
);
