import { Badge, Col, Dropdown, Icon, Input, Row, Select } from 'antd';
import Link from 'next/link';
import React from 'react';
import DebounceInput from 'react-debounce-input';
import SearchBox from '../SearchBox';

const { Option } = Select;

export const NavbarDesktop = ({
  accountDropdown,
  auth,
  cart,
  clickedOutside,
  customer,
  onSearchProducts,
  products,
  search,
  searchVisible,
  total,
  settings,
  onClickShowAll,
  onClickProduct,
  t
}) => {
  return (
    <div className="desktop-navbar">
      <Row className="pt-3" type="flex" justify="space-between" align="middle">
        <Col>
          <Link href="/catalog?location=">
            <a>
              <img src="/static/easis-logo.jpg" alt="logo" className="logo hover" />
            </a>
          </Link>
        </Col>
        {/* search bar */}
        <Col span={8}>
          <DebounceInput
            allowClear
            className="debounce-input"
            debounceTimeout={500}
            element={Input.Search}
            loading={search ? search.loading : false}
            minLength={2}
            value={search.text}
            onChange={onSearchProducts}
            placeholder={t('searchPlaceholder')}
          />
          {searchVisible ? (
            <SearchBox
              clickedOutside={clickedOutside}
              onClickProduct={onClickProduct}
              onClickShowAll={onClickShowAll}
              products={products}
              settings={settings}
              t={t}
              text={search}
              total={total}
            />
          ) : null}
        </Col>
        <Col span={8}>
          <Row type="flex" justify="space-around">
            <Col className="hover">
              {auth.authenticated ? (
                <Dropdown overlay={accountDropdown} trigger={['click']}>
                  <Row gutter={5} type="flex" justify="space-between" align="middle">
                    <Col>
                      <Icon type="user" style={{ fontSize: 19 }} />
                    </Col>
                    <Col>
                      <a className="text-16-normal">{t('AccountDrawer.title')}</a>
                    </Col>
                  </Row>
                </Dropdown>
              ) : (
                <Row gutter={5} type="flex" justify="space-between" align="middle">
                  <Col>
                    <Link href="/account">
                      <Icon type="user" style={{ fontSize: 19 }} />
                    </Link>
                  </Col>
                  <Col>
                    <Link href="/account">
                      <a className="text-16-normal">{t('AccountDrawer.title')}</a>
                    </Link>
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="hover">
              <Row gutter={5} type="flex" justify="space-between" align="middle">
                <Col>
                  <Link href="/favorites">
                    <Badge count={customer?.favorites?.length} offset={[-3, -6]}>
                      <Icon
                        type="heart"
                        style={{
                          color: '#888888',
                          fontSize: 21,
                          marginRight: 5
                        }}
                      />
                    </Badge>
                  </Link>
                </Col>
                <Col>
                  <Link href="/favorites">
                    <a className="text-16-normal">{t('favorites')}</a>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col className="hover">
              <Row gutter={5} type="flex" justify="space-between" align="middle">
                <Col>
                  <Link href="/cart">
                    <Badge count={cart.products.length} offset={[-3, -5]}>
                      <Icon
                        style={{
                          color: '#888888',
                          fontSize: 23,
                          marginRight: 5
                        }}
                        type="shopping-cart"
                      />
                    </Badge>
                  </Link>
                </Col>
                <Col>
                  <Link href="/cart">
                    <a className="text-16-normal">{t('cart')}</a>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* language selection */}
        <Col>
          <Select className="transparent-select" defaultValue="EN">
            <Option value="greek">
              <span>ΕΛ</span>
            </Option>
            <Option value="english">
              <span>EN</span>
            </Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default NavbarDesktop;
