import Link from 'next/link';
import React from 'react';
import { Col, Divider, Row } from 'antd';

const FooterTablet = ({ stores }) => {
  return (
    <>
      <Row type="flex" justify="start" align="middle">
        <Col className="mb-70" span={24}>
          <Divider />
        </Col>
        <Col className="footer-container" span={24}>
          <Row type="flex" justify="start">
            {/* ΧΡΗΣΙΜΟΙ ΣΥΝΔΕΣΜΟΙ ΩΡΑΡΙΟ ΚΑΤΑΣΤΗΜΑΤΩΝ */}
            <Col span={8}>
              <Row type="flex">
                <Col className="mb-10" span={24}>
                  <a className="text-16-bold">ΧΡΗΣΙΜΟΙ ΣΥΝΔΕΣΜΟΙ</a>
                </Col>
                <Col span={24}>
                  <Link href="/about">
                    <span className="text-16-normal">Η εταιρεία</span>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link href="/terms">
                    <a className="text-16-normal">Όροι Χρήσης</a>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link href="/returns">
                    <a className="text-16-normal">Πολιτική Επιστροφών</a>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link href="/payment">
                    <a className="text-16-normal">Πολιτική Πληρωμών</a>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link href="/shipping">
                    <a className="text-16-normal">Μεταφορικά</a>
                  </Link>
                </Col>
              </Row>
              <Row className="mt-70" type="flex">
                <Col className="mb-10" span={24}>
                  <span className="text-16-bold">ΩΡΑΡΙΟ ΚΑΤΑΣΤΗΜΑΤΩΝ</span>
                </Col>
                <Col className="mb-10" span={24}>
                  <Row type="flex">
                    <Col span={24}>
                      <span className="text-16-normal">ΔΕΥΤΕΡΑ - ΠΑΡΑΣΚΕΥΗ</span>
                    </Col>
                    <Col span={24}>
                      <span className="text-16-normal">12.00 - 21.00</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ */}
            <Col span={8}>
              <Row type="flex">
                <Col className="mb-10" span={24}>
                  <span className="text-16-bold">ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ</span>
                </Col>
                <Col span={24}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Easis-%CE%9A%CE%B5%CE%BD%CF%84%CF%81%CE%BF-%CE%95%CE%BD%CE%B1%CE%BB%CE%BB%CE%B1%CE%BA%CF%84%CE%B9%CE%BA%CE%B7%CF%82-%CE%99%CE%B1%CF%84%CF%81%CE%B9%CE%BA%CE%B7%CF%82-102375501394519/?__tn__=%2Cd%2CP-R&eid=ARAoxbGECTVJZK8EiL9wSiFpr7poUl6js4suL4Hb3pY2VSo9f-RGC9Mfl0TV_pMxrR0WA0jDYKQS1RGD"
                  >
                    <span className="text-16-normal">Facebook</span>
                  </a>
                </Col>
                <Col span={24}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/easis.greece/"
                  >
                    <span className="text-16-normal">Instagram</span>
                  </a>
                </Col>
                <Col span={24}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/Easis_greece"
                  >
                    <span className="text-16-normal">Twitter</span>
                  </a>
                </Col>
              </Row>
            </Col>
            {/* ΚΑΤΑΣΤΗΜΑΤΑ */}
            <Col span={8}>
              <Row type="flex">
                <Col className="mb-10" span={24}>
                  <span className="text-16-bold">ΚΑΤΑΣΤΗΜΑΤΑ</span>
                </Col>
                {stores?.map((store) => {
                  return (
                    <Col className="mb-20" span={24} key={store._id}>
                      <Row type="flex">
                        <Col span={24}>
                          <span className="text-16-bold">{store.name}</span>
                        </Col>
                        <Col span={24}>
                          <span className="text-16-normal">{store.address_1}</span>
                        </Col>
                        <Col span={24}>
                          <span className="text-16-normal">
                            Τηλέφωνο <a href={`tel:${store.phone_1}`}> {store.phone_1}</a>
                          </span>
                        </Col>
                        <Col span={24}>
                          <span className="text-16-normal">FAX {store.fax}</span>
                        </Col>
                        <Col span={24}>
                          <span className="text-16-normal">
                            Email <a href={`mailto:${store.email_1}`}>{store.email_1}</a>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider type="horizontal" />
          <Row
            className="footer-container mt-40 mb-40"
            type="flex"
            justify="center"
            align="middle"
          >
            <Col span={12} align="center">
              <span className="text-12-bold">TWENTY ONE</span>
              <span className="text-12-normal"> PRODUCTION</span>
            </Col>
            <Col span={12} align="center">
              <span className="text-12-normal"> ECOMMERCE PLATFORM NEXTCOMMERCE</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FooterTablet;
