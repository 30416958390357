import App from 'next/app';
import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { orderBy } from 'lodash';
import { DefaultSeo } from 'next-seo';
import axios from 'axios';
import Router from 'next/router';
import * as Sentry from '@sentry/browser';
import withRedux from 'next-redux-wrapper';
import { Provider } from 'react-redux';
import '../assets/App.less';
import SEO from '../config/next-seo.config';
import Layout from './Layout/Layout';
import { apiUrlPublic, axiosCallApi } from '../config/config';
import { checkServerSideCookie } from './store/actions/auth.actions';
import { makeStore } from './store/store';
import { appWithTranslation, i18n } from './i18n/i18n';
import { MediaContextProvider } from './Media';

import vars from '../config/vars';

if (typeof window !== 'undefined') {
  Sentry.init({ dsn: vars.publicSentryDSN, debug: false });
}

if (process.env.NODE_ENV !== 'production') {
  const refreshChunkStyle = (chunkFileName) => {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');

    style.rel = 'stylesheet';
    style.href = `${chunkFileName}?ts=${new Date().getTime()}`;
    head.append(style);

    const chunks = document.querySelectorAll(`link[href*="${chunkFileName}"]`);

    // delete all chunks css, except the last one. (delay 1s to keep the page from flickering)
    setTimeout(
      () =>
        chunks.forEach(
          (c, i) =>
            i !== chunks.length - 1 && c && c.parentNode && c.parentNode.removeChild(c)
        ),
      1000
    );
  };

  Router.events.on('routeChangeComplete', () => {
    refreshChunkStyle('/_next/static/css/styles.chunk.css');
  });
}

export default withRedux(makeStore)(
  appWithTranslation(
    class MyApp extends App {
      static getInitialProps = async ({ Component, ctx }) => {
        const pageProps = Component.getInitialProps
          ? await Component.getInitialProps(ctx)
          : {};

        try {
          await checkServerSideCookie(ctx);
          const { auth } = ctx.store.getState();

          const fetchCategories = await axios.get(`${apiUrlPublic}/category`);
          const fetchSettings = await axios.get(`${apiUrlPublic}/settings`);
          const fetchStores = await axiosCallApi.get(`${apiUrlPublic}/stores`);
          const { stores } = await fetchStores.data;
          const { categories } = await fetchCategories.data;
          const sortedCategories = orderBy(categories, 'weight', 'asc');
          const { settings } = await fetchSettings.data;
          return {
            auth,
            categories: sortedCategories,
            settings,
            stores,
            pageProps
          };
        } catch (error) {
          const errorEventId = Sentry.captureException(error);
          console.log('error in app.js', error);
          return {
            auth: { authenticated: false, accessToken: '' },
            categories: [],
            errorEventId,
            settings: { settings: { shipping_countries: [] } },
            stores: { stores: [] },
            pageProps
          };
        }
      };

      componentDidMount() {
        const lan = localStorage.getItem('language');
        if (lan === 'el') {
          document.body.classList.add('el');
        } else {
          document.body.classList.remove('el');
        }
      }

      componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
          Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key]);
          });

          Sentry.captureException(error);
        });
        super.componentDidCatch(error, errorInfo);
      }

      render() {
        const {
          auth,
          categories,
          Component,
          pageProps,
          settings,
          store,
          stores
        } = this.props;

        if (typeof window !== 'undefined') {
          if (!localStorage.getItem('language')) {
            localStorage.setItem('language', i18n.language);
          }
        }

        return (
          <MediaContextProvider>
            <DefaultSeo {...SEO} />
            <Provider store={store}>
              <PersistGate persistor={store.__persistor} loading={null}>
                <div className="body-container">
                  <Layout
                    categories={categories}
                    settings={settings}
                    stores={stores}
                    auth={auth}
                  >
                    <Component {...pageProps} />
                  </Layout>
                </div>
              </PersistGate>
            </Provider>
          </MediaContextProvider>
        );
      }
    }
  )
);
