import actionTypes from './action.types';

const {
  SEARCH_RESET_SEARCH_CONTAINER,
  SEARCH_RESET_SEARCH_TEXT,
  SEARCH_SET_SEARCH_CONTAINER,
  SEARCH_SET_SEARCH_TEXT,
  SEARCH_START_LOADING,
  SEARCH_STOP_LOADING,
  SEARCH_START_CLICK_LOADING,
  SEARCH_STOP_CLICK_LOADING
} = actionTypes;

export const setSearchText = (text) => ({
  type: SEARCH_SET_SEARCH_TEXT,
  text
});

export const resetSearchText = () => ({
  type: SEARCH_RESET_SEARCH_TEXT
});

export const setSearchContainer = () => ({
  type: SEARCH_SET_SEARCH_CONTAINER
});

export const resetSearchContainer = () => ({
  type: SEARCH_RESET_SEARCH_CONTAINER
});

export const startSearchLoading = () => ({
  type: SEARCH_START_LOADING
});

export const stopSearchLoading = () => ({
  type: SEARCH_STOP_LOADING
});

export const startSearchClickLoading = () => ({
  type: SEARCH_START_CLICK_LOADING
});

export const stopSearchClickLoading = () => ({
  type: SEARCH_STOP_CLICK_LOADING
});
