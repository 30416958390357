import update from 'immutability-helper';

const initialState = {
  text: '',
  searchContainer: false,
  loading: false,
  clickLoading: false
};

const startSearchLoading = (state) => {
  return update(state, {
    loading: {
      $set: true
    }
  });
};

const stopSearchLoading = (state) => {
  return update(state, {
    loading: {
      $set: false
    }
  });
};

const startSearchClickLoading = (state) => {
  return update(state, {
    clickLoading: {
      $set: true
    }
  });
};

const stopSearchClickLoading = (state) => {
  return update(state, {
    clickLoading: {
      $set: false
    }
  });
};

const setSearchText = (state, action) => {
  return update(state, {
    text: {
      $set: action.text
    }
  });
};

const setSearchContainer = (state) => {
  return update(state, {
    searchContainer: {
      $set: true
    }
  });
};

const resetSearchContainer = (state) => {
  return update(state, {
    searchContainer: {
      $set: false
    }
  });
};

const resetSearchText = (state) => {
  return update(state, {
    clickLoading: { $set: false },
    loading: { $set: false },
    searchContainer: { $set: false },
    text: { $set: '' }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_SET_SEARCH_TEXT':
      return setSearchText(state, action);
    case 'SEARCH_RESET_SEARCH_TEXT':
      return resetSearchText(state);
    case 'SEARCH_SET_SEARCH_CONTAINER':
      return setSearchContainer(state);
    case 'SEARCH_RESET_SEARCH_CONTAINER':
      return resetSearchContainer(state);
    case 'SEARCH_START_LOADING':
      return startSearchLoading(state);
    case 'SEARCH_STOP_LOADING':
      return stopSearchLoading(state);
    case 'SEARCH_START_CLICK_LOADING':
      return startSearchClickLoading(state);
    case 'SEARCH_STOP_CLICK_LOADING':
      return stopSearchClickLoading(state);
    default:
      return {
        ...state
      };
  }
};

export default reducer;
