import update from 'immutability-helper/index';
import actionTypes from '../actions/action.types';

const initialState = {
  accessToken: '',
  authenticated: false,
  email: '',
  ready: false,
  language: ''
};

const authLogout = (state) => {
  return update(state, {
    accessToken: { $set: '' },
    authenticated: { $set: false },
    email: { $set: '' },
    language: { $set: '' },
    ready: { $set: false }
  });
};

const authFail = (state) => {
  return update(state, {
    accessToken: { $set: '' },
    authenticated: { $set: false },
    email: { $set: '' },
    language: { $set: '' },
    ready: { $set: false }
  });
};

const authSuccess = (state, action) => {
  return update(state, {
    accessToken: { $set: action.accessToken },
    authenticated: { $set: true },
    email: { $set: action.customer.email },
    ready: { $set: true },
    language: { $set: action.language }
  });
};

const setFavorites = (state, action) => {
  return update(state, {
    favorites: {
      $set: action.favorites
    }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_RESET:
      return authLogout(state, action);
    case actionTypes.CUSTOMER_UPDATE_FAVORITES:
      return setFavorites(state, action);
    default:
      return state;
  }
};

export default reducer;
