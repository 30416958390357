import { Col, Menu, Row } from 'antd';
import Link from 'next/link';
import React from 'react';

const { SubMenu } = Menu;

const MobileMenu = ({
  drawerHeader,
  menu,
  resetProductCatalog,
  resetProductFilters,
  resetText,
  toggleDrawer
}) => {
  return (
    <div>
      <Row>
        <Col className="ml-20 mt-50">
          <span className="text-18-bold">{drawerHeader}</span>
        </Col>
        <Col className="mt-20">
          <Menu
            defaultOpenKeys={['sub1']}
            defaultSelectedKeys={['1']}
            mode="inline"
            style={{ width: '100%' }}
          >
            {menu.map((data) => {
              return (
                <SubMenu
                  key={data._id}
                  title={
                    <div className="ml-20">
                      <span className="text-16-normal">{data.title}</span>
                    </div>
                  }
                >
                  {data.children.map((item) => {
                    return (
                      <Menu.SubMenu
                        className="inner-sub"
                        title={<p className="text-16-normal menu-title">{item.title}</p>}
                        key={item.id}
                      >
                        {!item.compact ? (
                          item.children.length > 0 ? (
                            <ul className="inner-category-list">
                              {/* {categoryChildren(item.children)} */}
                              {item.children.map((children) => {
                                return (
                                  <Menu.Item
                                    className="drawer-menu-item "
                                    key={children._id}
                                  >
                                    <li className="text-dots mb-2">
                                      <Link href="/catalog-e-asis" as="/catalog-e-asis">
                                        <span
                                          className="inner-children-title"
                                          onClick={() => {
                                            resetProductCatalog(children.url);
                                            resetProductFilters(children.url);
                                            resetText();
                                            toggleDrawer();
                                          }}
                                        >
                                          {children.title}
                                        </span>
                                      </Link>
                                    </li>
                                  </Menu.Item>
                                );
                              })}
                            </ul>
                          ) : null
                        ) : null}
                      </Menu.SubMenu>
                    );
                  })}
                </SubMenu>
              );
            })}
          </Menu>
        </Col>
      </Row>
    </div>
  );
};

export default MobileMenu;
