import { Button, Col, Row } from 'antd';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { formatCurrency } from '../../../../utils/utils';

const SearchBox = ({
  clickedOutside,
  onClickProduct,
  onClickShowAll,
  products,
  settings,
  t,
  text,
  total
}) => {
  SearchBox.handleClickOutside = () => clickedOutside();

  return (
    <Col className="search-dropdown-menu-desktop mt-10" span={24}>
      <Row className="ml-20 mt-20 mb-20 pl-3 pr-3 pb-3">
        {products.length === 0 ? (
          <>
            <div>{t('SearchBox.zeroResults')}</div>
          </>
        ) : (
          <>
            <Col className="mb-30 d-flex align-items-center mb-10">
              <span className="text-16-mute">
                {total} {t('SearchBox.foundProductsFor')}
              </span>
              <span className="text-16-bold pl-1">{text.text}</span>
            </Col>
            <div className="search-results__articles">
              <ul>
                {/* eslint-disable-next-line array-callback-return */}
                {products.map((product, i) => {
                  if (i < 4) {
                    return (
                      <li key={product._id} onClick={() => onClickProduct(product)}>
                        <Row type="flex" justify="start" className="hover">
                          <Col className="text-16-normal search-results__articles-item">
                            {product.title}
                          </Col>
                          <Col className="prices d-flex">
                            {product.onSale.value ? (
                              <>
                                <span className="from-price line-through mr-10">
                                  {formatCurrency(product.price, settings)}
                                </span>
                                <span className="current-price text-16-bold">
                                  {formatCurrency(product.sale.salePrice, settings)}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="current-price">
                                  {formatCurrency(product.price, settings)}
                                </span>
                              </>
                            )}
                          </Col>
                        </Row>
                      </li>
                    );
                  }
                })}
              </ul>
              <Col className="text-center mt-20">
                <Button onClick={onClickShowAll} className="outlined-button">
                  {t('SearchBox.showAllProducts')} ({total})
                </Button>
              </Col>
            </div>
          </>
        )}
      </Row>
    </Col>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => SearchBox.handleClickOutside,
  excludeScrollbar: true
};

export default onClickOutside(SearchBox, clickOutsideConfig);
