import React from 'react';
import { connect } from 'react-redux';
import './footer.less';
import FooterMobile from './components/Mobile/FooterMobile';
import FooterTablet from './components/Tablet/FooterTablet';
import FooterDesktop from './components/Desktop/FooterDesktop';
import { Media } from '../../../Media';

const Footer = ({ stores }) => {
  return (
    <>
      <Media at="xs">
        <div className="footer-mobile-container">
          <FooterMobile stores={stores} />
        </div>
      </Media>
      <Media at="md">
        <div className="footer-tablet-container">
          <FooterTablet stores={stores} />
        </div>
      </Media>
      <Media greaterThan="md">
        <div className="footer-desktop-container mt-50">
          <FooterDesktop stores={stores} />
        </div>
      </Media>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stores: state.stores.stores
  };
};

export default connect(mapStateToProps)(Footer);
