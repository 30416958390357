import update from 'immutability-helper';
import { FETCH_MENU_INIT, FETCH_MENU_SUCCESS } from '../actions/types';

const initialState = {
  menuItems: [],
  isLoading: false
};

const fetchMenuInit = () => {
  return update(initialState, {
    isLoading: {
      $set: true
    }
  });
};

const fetchMenuSuccess = (state, action) => {
  return update(initialState, {
    isLoading: {
      $set: false
    },
    menuItems: {
      $set: action.payload.data
    }
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_INIT:
      return fetchMenuInit(state, action);
    case FETCH_MENU_SUCCESS:
      return fetchMenuSuccess(state, action);
    default:
      return state;
  }
};
