import update from 'immutability-helper';

const initialState = {
  settings: {}
};

const setStoreSettings = (state, action) => {
  return update(state, {
    settings: {
      $set: action.settings[0]
    }
  });
};

export const reducer = (state = initialState, action) => {
  if (action.type === 'STORE_SET_SETTINGS') {
    return setStoreSettings(state, action);
  } else {
    return {
      ...state
    };
  }
};

export default reducer;
