import update from 'immutability-helper';

const initialState = {
  favorites: [],
  tempCart: ''
};

const addToFavorites = (state, action) => {
  const { favorite } = action;
  // don't ask why, that's how imm helper works
  let updatedFavorites = [];
  return update(state, {
    favorites: {
      $apply: (items) => {
        const index = items.findIndex((item) => favorite === item);
        if (index > -1) {
          updatedFavorites = update(items, {
            $splice: [[index, 1]]
          });
        } else {
          updatedFavorites = update(items, {
            $push: [favorite]
          });
        }
        return updatedFavorites;
      }
    }
  });
};

// eslint-disable-next-line consistent-return
const removeFavorite = (state, action) => {
  const { favorite } = action;
  const index = state.favorites.findIndex((item) => favorite === item);
  if (index > -1) {
    return update(state, {
      favorites: {
        $splice: [[index, 1]]
      }
    });
  }
};

const updateFavorites = (state, action) => {
  return update(state, {
    favorites: {
      $set: action.favorites
    }
  });
};

const setCart = (state, action) => {
  return update(state, {
    tempCart: {
      $set: action.tempCart
    }
  });
};

const emptyCart = (state) => {
  return update(state, {
    tempCart: {
      $set: []
    }
  });
};

const syncFavorites = (state, action) => {
  return update(state, {
    favorites: {
      $set: action.favorites
    }
  });
};

const clearFavorites = (state) => {
  return update(state, {
    favorites: {
      $set: []
    }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_ADD_TO_FAVORITES':
      return addToFavorites(state, action);
    case 'CUSTOMER_REMOVE_FAVORITE':
      return removeFavorite(state, action);
    case 'CUSTOMER_UPDATE_FAVORITES':
      return updateFavorites(state, action);
    case 'CUSTOMER_SYNC_FAVORITES':
      return syncFavorites(state, action);
    case 'CUSTOMER_SET_CART':
      return setCart(state, action);
    case 'CUSTOMER_CLEAR_FAVORITES':
      return clearFavorites(state);
    case 'CUSTOMER_EMPTY_TEMP_CART':
      return emptyCart(state);
    default:
      return {
        ...state
      };
  }
};

export default reducer;
