/* eslint-disable */
import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import cookie from 'js-cookie';
import actionTypes from './action.types';
import { axiosCallApi } from '../../../config/config';

/**
 * cookie helper methods
 */

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 3600
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1
    });
  }
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const getCookie = (key, req) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);
};

export const authSuccess = (auth, customer) => {
  const { accessToken } = auth;
  axiosCallApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  return {
    accessToken,
    customer,
    type: actionTypes.AUTH_SUCCESS
  };
};

export const authFail = () => {
  removeCookie('token');
  return {
    type: actionTypes.AUTH_FAIL
  };
};

export const logout = () => {
  axiosCallApi.defaults.headers.common.Authorization = '';
  removeCookie('token');
  return {
    type: actionTypes.AUTH_RESET
  };
};

export const authCheck = (token) => {
  return (dispatch, getState) => {
    let { auth, customer } = getState();
    if (token) {
      const decoded = jwtDecode(token);
      const dateNow = new Date();
      if (decoded.exp < dateNow.getTime() / 1000) return dispatch(logout());
      axiosCallApi.defaults.headers.common.Authorization = `Bearer ${token}`;
      setCookie('token', token);
      auth = update(auth, {
        accessToken: { $set: token }
      });
      dispatch(authSuccess(auth, customer));
    } else if (auth.accessToken) {
      const decoded = jwtDecode(auth.accessToken);
      const dateNow = new Date();

      if (decoded.exp < dateNow.getTime() / 1000) return dispatch(logout());
      axiosCallApi.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`;
      setCookie('token', auth.accessToken);
      dispatch(authSuccess(auth, customer));
    } else if (!auth.accessToken) {
      dispatch(logout());
    }
  };
};

export const checkServerSideCookie = async (ctx) => {
  if (ctx.isServer) {
    if (ctx.req.headers.cookie) {
      const token = getCookie('token', ctx.req);
      await ctx.store.dispatch(authCheck(token));
    }
  } else {
    return ctx.store.getState().auth.accessToken;
  }
};
