export default {
  title: 'Easis | Πεπτιδια για την βελτιωση της υγειας.',
  description:
    'Πεπτιδια για την βελτιωση της υγειας, την αποκατάσταση και την πρόληψη της πρόωρης γήρανσης και των ασθενειών.',
  openGraph: {
    type: 'website',
    locale: 'el_GR',
    url: 'https://eshop.e-asis.com/',
    title: 'Easis | Πεπτιδια για την βελτιωση της υγειας.',
    description:
      'Πεπτιδια για την βελτιωση της υγειας, την αποκατάσταση και την πρόληψη της πρόωρης γήρανσης και των ασθενειών.',
    image: '',
    imageWidth: 1200,
    imageHeight: 1200,
    site_name: 'Easis | Πεπτιδια για την βελτιωση της υγειας.',
    images: [
      {
        url: '',
        alt: '',
        width: 800,
        height: 600
      }
    ]
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image'
  }
};
