import update from 'immutability-helper';

const initialState = {
  stores: []
};

const setStores = (state, action) => {
  return update(state, {
    stores: {
      $set: action.stores || []
    }
  });
};

export const reducer = (state = initialState, action) => {
  if (action.type === 'STORE_SET_STORES') {
    return setStores(state, action);
  } else {
    return {
      ...state
    };
  }
};

export default reducer;
