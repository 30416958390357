/* eslint-disable */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStateSyncMiddleware } from 'redux-state-sync';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { createLogger } from 'redux-logger';
import auth from './reducers/auth.reducer';
import cartReducer from './reducers/cart.reducer';
import customerReducer from './reducers/customer.reducer';
import catalogReducer from './reducers/catalog.reducer';
import filtersReducer from './reducers/filters.reducer';
import storeReducer from './reducers/stores.reducer';
import settingsReducer from './reducers/settings.reducer';
import searchReducer from './reducers/search.reducer';
import menuReducer from './reducers/menu.reducer';
import productsReducer from './reducers/products.reducer';
import orderReducer from './reducers/order.reducer';
// import crossBrowserListener from '../utils/persistListener';

const server = typeof window === 'undefined';

const sessionStorage = require('redux-persist/lib/storage/session').default;
const storage = require('redux-persist/lib/storage').default;

const rootPersistConfig = {
  key: 'root',
  whitelist: ['catalog', 'search', 'settings', 'stores'],
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2
};

const authPersistConfig = {
  key: 'auth',
  blacklist: ['cart', 'catalog', 'customer', 'filters', 'search', 'settings', 'stores'],
  storage,
  stateReconciler: hardSet
};

const cartPersistConfig = {
  key: 'cart',
  blacklist: ['auth', 'catalog', 'customer', 'filters', 'search', 'settings', 'stores'],
  storage,
  stateReconciler: autoMergeLevel2
};

const orderPersistConfig = {
  key: 'order',
  blacklist: ['cart', 'catalog', 'customer', 'filters', 'search', 'settings', 'stores'],
  storage,
  stateReconciler: autoMergeLevel2
};

const filtersPersistConfig = {
  key: 'filters',
  blacklist: ['auth', 'catalog', 'customer', 'search', 'settings', 'stores'],
  storage,
  stateReconciler: autoMergeLevel2
};

const customerPersistConfig = {
  key: 'customer',
  blacklist: ['auth', 'cart', 'catalog', 'filters', 'search', 'settings', 'stores'],
  storage,
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  cart: persistReducer(cartPersistConfig, cartReducer),
  catalog: catalogReducer,
  customer: persistReducer(customerPersistConfig, customerReducer),
  filters: persistReducer(filtersPersistConfig, filtersReducer),
  stores: storeReducer,
  search: searchReducer,
  menu: menuReducer,
  products: productsReducer,
  settings: settingsReducer,
  order: persistReducer(orderPersistConfig, orderReducer)
});

const initState = {
  auth: {
    accessToken: '',
    authenticated: false,
    email: '',
    language: '',
    ready: false
  },
  cart: {
    products: []
  },
  customer: {
    favorites: [],
    tempCart: []
  },
  filters: {
    attributes: [],
    brands: [],
    options: [],
    pricesRange: {},
    tags: []
  },
  catalog: {
    brands: [],
    categoryAncestors: [],
    categoryUrl: '',
    count: 0,
    loading: false,
    order: 'price',
    page: 1,
    pageSize: '12',
    pageSizes: ['12', '24', '36', '48', '60', '72', '84', '96'],
    parentUrl: '',
    products: [],
    redrawKey: 1,
    sort: 1,
    tags: [],
    searchContainer: false
  },
  search: {
    text: '',
    searchContainer: false,
    loading: false
  },
  settings: { settings: { shipping_countries: [] } },
  stores: { stores: [] },
  order: {}
};

const logger = createLogger({ collapsed: true });

const syncConfig = {
  whitelist: [
    'AUTH_RESET',
    'AUTH_SUCCESS',
    'CART_ADD_PRODUCT',
    'CART_RESET',
    'CUSTOMER_ADD_TO_FAVORITES',
    'CUSTOMER_CLEAR_FAVORITES',
    'CUSTOMER_EMPTY_TEMP_CART',
    'CUSTOMER_SET_CART',
    'CUSTOMER_SYNC_FAVORITES'
  ]
};

const middleware = [thunk, logger];

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  middleware.push(require('redux-immutable-state-invariant').default());
}

if (!server) {
  middleware.push(createStateSyncMiddleware(syncConfig));
}

const enhancer = composeWithDevTools(applyMiddleware(...middleware));

// eslint-disable-next-line import/prefer-default-export
const initStore = (reducer, initialState = initState) => {
  return createStore(reducer, initialState, enhancer);
};

// eslint-disable-next-line import/prefer-default-export
export const makeStore = (initialState, { isServer }) => {
  // return createStore(rootReducer, initialState, enhancer);
  if (isServer) {
    initialState = initState || { server: true };
    return initStore(rootReducer, initialState);
  } else {
    // we need it only on client side
    // eslint-disable-next-line global-require
    const { persistStore } = require('redux-persist');
    // eslint-disable-next-line global-require
    const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
    const store = initStore(persistedReducer, initialState);

    // crossBrowserListener(store, persistConfig, { blacklist: ['filters', 'search'] });

    store.__persistor = persistStore(store);

    return store;
  }
};
