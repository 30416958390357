module.exports = {
  apiUrl:
    process.env.NODE_ENV === 'development'
      ? process.env.CUSTOM_PUBLIC_DEVELOPMENT_API_URL
      : process.env.CUSTOM_PUBLIC_PRODUCTION_API_URL,
  everyPayPublicKey:
    process.env.NODE_ENV === 'development'
      ? process.env.CUSTOM_PUBLIC_EVERYPAY_PUBLIC_SANDBOX_KEY
      : process.env.CUSTOM_PUBLIC_EVERYPAY_PUBLIC_PRODUCTION_KEY,
  publicSentryDSN: process.env.CUSTOM_PUBLIC_SENTRY_DSN,
  serverSentryDSN: process.env.CUSTOM_SERVER_SENTRY_DSN,
  siteName: process.env.CUSTOM_PUBLIC_SITE_NAME,
  seo: {
    title: process.env.CUSTOM_PUBLIC_SITE_NAME,
    description: process.env.CUSTOM_PUBLIC_SITE_SEO_DESCRIPTION,
    openGraph: {
      description: process.env.CUSTOM_PUBLIC_SITE_SEO_DESCRIPTION,
      image: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGE,
      imageHeight: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGE_HEIGHT,
      imageWidth: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGE_WIDTH,
      keywords: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_KEYWORDS,
      locale: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_LOCALE,
      site_name: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_SITE_NAME,
      title: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_TITLE,
      type: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_TYPE,
      url: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_URL,
      images: [
        {
          alt: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_ALT_1,
          height: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_1_IMAGE_HEIGHT,
          url: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_1,
          width: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_1_IMAGE_WIDTH
        },
        {
          alt: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_ALT_2,
          height: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_2_IMAGE_HEIGHT,
          url: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_2,
          width: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_2_IMAGE_WIDTH
        },
        {
          alt: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_ALT_3,
          height: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_3_IMAGE_HEIGHT,
          url: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_3,
          width: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_3_IMAGE_WIDTH
        },
        {
          alt: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_ALT_4,
          height: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_4_IMAGE_HEIGHT,
          url: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_4,
          width: process.env.CUSTOM_PUBLIC_SITE_SEO_OPEN_GRAPH_IMAGES_URL_4_IMAGE_WIDTH
        }
      ]
    },
    twitter: {
      cardType: process.env.CUSTOM_PUBLIC_SITE_SEO_TWITTER_CARD_TYPE
    }
  }
};
