import { Badge, Col, Dropdown, Icon, Input, Row } from 'antd';
import Link from 'next/link';
import React from 'react';
import DebounceInput from 'react-debounce-input';
import SearchBox from '../SearchBox';

const NavbarMobile = ({
  accountDropdown,
  auth,
  cart,
  clickedOutside,
  customer,
  onClickProduct,
  onClickShowAll,
  onSearchProducts,
  products,
  search,
  searchVisible,
  settings,
  t,
  toggleDrawer,
  total
}) => {
  return (
    <div className="mobile-navbar pt-15">
      <Row type="flex" justify="space-between" align="middle">
        {/* logo */}
        <Col>
          <Link href="/catalog?location=">
            <a>
              <img src="/static/easis-logo.jpg" alt="logo" className="logo hover" />
            </a>
          </Link>
        </Col>
        {/* menus */}
        <Col>
          <Row gutter={20} type="flex" justify="space-between" align="middle">
            {auth.authenticated ? (
              <Dropdown overlay={accountDropdown} trigger={['click']}>
                <Col>
                  <Icon type="user" style={{ fontSize: 25 }} />
                </Col>
              </Dropdown>
            ) : (
              <Col>
                <Link href="/account">
                  <a>
                    <Icon type="user" style={{ fontSize: 25 }} />
                  </a>
                </Link>
              </Col>
            )}
            <Col>
              <Link href="/favorites">
                <a>
                  <Badge count={customer?.favorites?.length} offset={[-5, -9]}>
                    <Icon
                      className="icon"
                      type="heart"
                      style={{ fontSize: 25, color: '#888888' }}
                    />
                  </Badge>
                </a>
              </Link>
            </Col>
            <Col className="mr-10">
              <Link href="/cart">
                <a>
                  <Badge count={cart.products.length} offset={[-5, -8]}>
                    <Icon
                      className="icon"
                      type="shopping-cart"
                      style={{ fontSize: 25, color: '#888888' }}
                    />
                  </Badge>
                </a>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="ml-10 mt-10" type="flex" justify="start">
        {/* menu icon */}
        <Col>
          <Icon
            className="icon"
            onClick={toggleDrawer}
            style={{ fontSize: 30 }}
            type="menu"
          />
        </Col>
      </Row>
      {/* search */}
      <Row className="mt-20" type="flex" align="middle" justify="center">
        <Col span={24}>
          <DebounceInput
            allowClear
            className="debounce-input"
            debounceTimeout={500}
            element={Input.Search}
            loading={search ? search.loading : false}
            minLength={2}
            onChange={onSearchProducts}
            placeholder={t('searchPlaceholder')}
            value={search.text}
          />
        </Col>
        <Col className="mt-10" span={24}>
          {searchVisible ? (
            <SearchBox
              clickedOutside={clickedOutside}
              onClickProduct={onClickProduct}
              onClickShowAll={onClickShowAll}
              products={products}
              settings={settings}
              t={t}
              text={search}
              total={total}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default NavbarMobile;
