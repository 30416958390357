import update from 'immutability-helper';

const initialState = {
  attributes: [],
  brands: [],
  options: [],
  pricesRange: {},
  tags: []
};

// SET
const setAttributesFilter = (state, action) => {
  return update(state, {
    attributes: { $set: action.attributes }
  });
};

const setBrandsFilter = (state, action) => {
  return update(state, {
    brands: { $set: action.brands }
  });
};

const setOptionsFilter = (state, action) => {
  return update(state, {
    options: { $set: action.options }
  });
};

const setRangeFilter = (state, action) => {
  return update(state, {
    pricesRange: { $set: action.pricesRange }
  });
};

const setSearchFilter = (state, action) => {
  return update(state, {
    search: { $set: action.search }
  });
};

const setTagsFilter = (state, action) => {
  return update(state, {
    tags: { $set: action.tags }
  });
};

// RESET
const resetAllFilters = (state) => {
  return update(state, {
    attributes: { $set: [] },
    brands: { $set: [] },
    options: { $set: [] },
    pricesRange: { $set: {} },
    tags: { $set: [] }
  });
};

const resetAttributesFilter = (state) => {
  return update(state, {
    attributes: {
      $set: []
    }
  });
};

const resetBrandsFilter = (state) => {
  return update(state, {
    brands: {
      $set: []
    }
  });
};

const resetOptionsFilter = (state) => {
  return update(state, {
    options: {
      $set: []
    }
  });
};

const resetRange = (state) => {
  return update(state, {
    pricesRange: {
      $set: []
    }
  });
};

const resetSearchFilter = (state) => {
  return update(state, {
    search: {
      $set: ''
    }
  });
};

const resetTagsFilter = (state) => {
  return update(state, {
    tags: {
      $set: []
    }
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // SET
    case 'FILTERS_SET_ATTRIBUTES':
      return setAttributesFilter(state, action);
    case 'FILTERS_SET_BRANDS':
      return setBrandsFilter(state, action);
    case 'FILTERS_SET_OPTIONS':
      return setOptionsFilter(state, action);
    case 'FILTERS_SET_RANGE':
      return setRangeFilter(state, action);
    case 'FILTERS_SET_SEARCH':
      return setSearchFilter(state, action);
    case 'FILTERS_SET_TAGS':
      return setTagsFilter(state, action);
    // RESET
    case 'FILTERS_RESET_ALL':
      return resetAllFilters(state);
    case 'FILTERS_RESET_ATTRIBUTES':
      return resetAttributesFilter(state, action);
    case 'FILTERS_RESET_BRANDS':
      return resetBrandsFilter(state);
    case 'FILTERS_RESET_OPTIONS':
      return resetOptionsFilter(state);
    case 'FILTERS_RESET_RANGE':
      return resetRange(state);
    case 'FILTERS_RESET_SEARCH':
      return resetSearchFilter(state);
    case 'FILTERS_RESET_TAGS':
      return resetTagsFilter(state);
    default:
      return {
        ...state
      };
  }
};

export default reducer;
